<template>
  <div>
    <Loading v-bind:showLoadScreen="screenLoading" />
    <Border v-bind:showLoading="checkingProvision">
      <!-- <div class="item"> -->
      <div v-if="showWaitMessage">
        <div class="lineItemHeader">Provisioning service...</div>
      </div>
      <div v-if="!showWaitMessage">
        <div v-if="status.status != 'PAID'">
          <div class="lineItemHeader">We are unable to verify if the transaction was successful</div>
          <div class="lineItemHeader">Please go to the Admin section and check your account settings</div>
          <div class="lineItemHeader">If there is a problem with your account settings, please use 'Talk to us' to send
            us a message</div>
        </div>
        <div v-if="status.status == 'PAID'">
          <div class="lineItemHeader">Provisioning successfull!</div>
          <div class="lineItemHeader">Redirecting to Home screen</div>

        </div>
      </div>
    </Border>
  </div>
</template>

<script>

import Comms from "../shared";
import Loading from "@/components/Loading.vue";
import Border from "@/components/Border.vue";
export default {
  name: "Home",
  components: {
    Border,
    Loading,
  },
  data: function () {
    return {
      status: {
        status: "",
      },
      screenLoading: false,
      showWaitMessage: true,
      checkingProvision: true,
      logRequestErrors: [],
      pollCount: 0,
      pollMax: 5,
      pollInterval: 3000,
      sessionID: ""

    };
  },
  methods: {
    // Stripe calls this page after success.
    // It will also call a webhook on the server to provision the data
    // Wait a while on this page, then check back to the server to ensure the account is in order.
    // do a popup to tell the user that you are provisioning the service. Use the scan check mechanism to poll the server
    goHome() {
      setTimeout(() => {
        this.$router.push({ name: "Home" });
      }, this.pollInterval);
    },
    init() {
      if (this.sessionID.length < 1) {
        this.sessionID = this.$route.query.session_id;
        if (!this.sessionID || this.sessionID.length < 1) {
          this.sessionID = "";
        }
        // console.log("session_id: " + this.sessionID);
      }
      setTimeout(() => {
        this.logRequestErrors = [];
        if (this.pollCount < this.pollMax) {
          this.pollCount++;
          Comms.sendToServer(
            "POST",
            "provisionDone",
            this.sessionID,
            this.logRequestErrors
          ).then((response) => {
            if (response && response.status == 200) {
              // polling again
              // console.log("Code is 200");
              this.status = JSON.parse(response.data);
              if (this.status.status.length > 0) {
                // responseMessageList = response.data.messages;
                // console.log("this.status.status: " + this.status.status);
                if (this.status.status == "PAID") {
                  this.checkingProvision = false;
                  this.showWaitMessage = false;
                  this.goHome();
                } else {
                  this.init();
                }
              } else {
                // console.log("Status empty...checking again ");
                this.init();
              }
            } else {
              // error occured. What to do
              // console.log("Code is NOT 200");
              // console.log("session_id: " + this.sessionID)
              this.checkingProvision = false;
              this.showWaitMessage = false;
              // this.init();
            }
          });
        } else {
          // polling count exceeded. Show error message
          // console.log("Polling count max reached: " + this.pollCount);
          this.checkingProvision = false;
          this.showWaitMessage = false;
        }
      }, this.pollInterval);
    },
  },

  mounted: function () {
    // if (!this.$store.state.authenticated) {
    //   this.$router.push({ name: "Home" });
    // } else {
    this.init();
    // }
  },
};
</script>

<style>
.centerQR {
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 20px;
}
</style>